// web submenu toggle

const subMenuLink = document.querySelector(".submenu");
const subMenuWrapper = document.querySelector(".submenu-wrapper");

const openMenu = () => {
    subMenuWrapper.classList.add("open");
};

const closeMenu = () => {
    subMenuWrapper.classList.remove("open");
};

subMenuLink.addEventListener("mouseenter", openMenu);
subMenuLink.addEventListener("mouseleave", closeMenu);

//mobile menu toggle

const menu = document.querySelector(".mobile-menu-icon");
const mainNav = document.querySelector(".main-navigation");
const navBar = document.querySelector(".nav-bar-container");

const toggleMobileMenu = () => {
    mainNav.classList.toggle("open");
    navBar.classList.toggle("open");
};

menu.addEventListener("click", toggleMobileMenu);

// footer fixed

const footer = document.querySelector("footer");
const footerOffsetSection = document.querySelector(".footer-space");


const footerOffset = () => {
    footerOffsetSection.style.setProperty(
        "--_footer-height",
        footer.offsetHeight + "px"
    );
};

window.addEventListener("load", footerOffset);
window.addEventListener("resize", footerOffset);

//function mailto

const contactMeSection = document.querySelector(".contact-me");
const chatBtnMobile = document.querySelector(".lets-chat-btn-mobile");

const mailTo = () => {
    window.location.href = "mailto:contact@waydzo.com";
};

contactMeSection.addEventListener("click", mailTo);
chatBtnMobile.addEventListener("click", mailTo);

// panipuri redirection

// const paniPuri = document.querySelector('.panipuri');
// const paniPuriWiki = () => {
//     window.location.href = "https://en.wikipedia.org/wiki/Panipuri"
// }
// paniPuri.addEventListener('click', paniPuriWiki)

// current year

const copyRightYear = document.getElementById("currentYear");
const currentYear = new Date().getFullYear();

copyRightYear.innerText = currentYear;
