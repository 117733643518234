// lazy load images
const lazyImages = document.querySelectorAll("[data-src]");

// about-me images extra class
// const aboutMeImageGrid = document.querySelector(".about-me-images-grid");

const preloadImage = (img) => {
  const src = img.getAttribute("data-src");
  //   console.log(src);

  if (!src) {
    return;
  }
  img.src = src;
};

const lazyImgObserver = new IntersectionObserver((entries, lazyImgObserver) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      return;
    } else {
      preloadImage(entry.target);
      // entry.target.classList.remove("lazy");
      lazyImgObserver.unobserve(entry.target);
    }
  });
});

lazyImages.forEach((image) => {
  lazyImgObserver.observe(image);
});

// lazyImgObserver.observe(aboutMeImageGrid);

// console.log(lazyImages);